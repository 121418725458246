import PropTypes from 'prop-types';
import * as React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import { Checkout as ProjectCart } from '@bgea-js/form-templates';
import Layout from '../components/Layout';
import * as Ui from '@bgea-js/react-ui';
import { Seo } from '../components/Seo';
import { useGiftOffer } from '../hooks/useGiftOffer';
import { LandingPageProjects } from '../constants/project';

function Checkout({ data }) {
  const giftOffer = useGiftOffer();
  let meta = {
    title: 'Billy Graham Evangelistic Association | Giving - Checkout',
    description:
      'We rely on the prayers and gifts of friends like you. Your support is being used by God to transform lives around the world.',
    socialTitle:
      'Make an online Gift to the Billy Graham Evangelistic Association',
  };
  let styles = {};
  const projects = data?.projects?.nodes
  .filter((item) => !LandingPageProjects.includes(item?.frontmatter?.projectCode))
  .map((item) => ({
    ...item?.frontmatter,
    projectId: item?.frontmatter?.projectCode,
  }));
  projects.sort((a, b) => a?.order - b?.order);
  return (
    <Layout meta={meta} styles={styles}>
      <Ui.Section className="py-3">
        <Ui.Container>
          <Ui.Breadcrumb>
            <Ui.Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Ui.Breadcrumb.Item>
            <Ui.Breadcrumb.Item active>
              <Link to="#" aria-current="page">
                Checkout
              </Link>
            </Ui.Breadcrumb.Item>
          </Ui.Breadcrumb>
          <hr />
        </Ui.Container>
        <Ui.Container className="checkout">
          <Ui.Section>
            <ProjectCart
              collectionType={1}
              country={['US']}
              destination={2}
              emailId="DonationReceiptBGEA"
              emptyCartMessage={'Your cart is empty'}
              currencyLimitMessage={
                'Please enter an amount of at least $5 to continue.'
              }
              esubCodes={['BGEA', 'PLTR']}
              giftOffer={giftOffer}
              deliveryStatement={
                '*While supplies last; please allow *4-6 weeks for delivery; U.S. residents only'
              }
              projects={projects}
              showUpgradeGift={true}
              showFreeGiftOffer={true}
              siteId={'bgea'}
            />
          </Ui.Section>
        </Ui.Container>
      </Ui.Section>
    </Layout>
  );
}

Checkout.propTypes = {
  data: PropTypes.shape({}),
};

export const pageQuery = graphql`
  query {
    projects: allMdx(
      filter: { frontmatter: { order: { ne: 0 } } }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        body
        fields {
          slug
        }
        frontmatter {
          title
          slug
          description
          projectCode
          order
        }
      }
    }
  }
`;

export default Checkout;

export const Head = ({ location, params, data, pageContext }) => {
  return (
    <Seo
      title="Billy Graham Evangelistic Association | Giving"
      description="Your gift today will share the Good News with others and point them to the love of our Heavenly Father."
      pathname={location.pathname}
    />
  );
};
